import React from "react";

const InputField = ({ placeholder, icon, value, onChange, css }) => {
  return (
    <div className="flex items-center border border-gray-300 rounded-full overflow-hidden focus-within:border-primary focus-within:border-primary">
      <span class="pl-3 text-gray-500">
        {icon}
      </span>
      <input
        className={css}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default InputField;
