/* eslint-disable no-unused-vars */
// /* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import wave from "../../assets/ic_wave.svg";
import { Checkbox } from "@mui/material";
import { BsBookmarkFill, BsFire } from "react-icons/bs";
import InputField from "../../components/InputField";
import Dropdown from "../../components/Dropdown";
import ChatInputField from "../../components/ChatInputField";
import { useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { checkAndRefreshToken } from "../../utils/authUtils";
import {
  bookmarkRecommendation,
  bookmarkDelete,
  searchByQuery,
  searchBookmarkByQuery,
  recommendationVisited,
  bookmarkVisited,
  fetchData,
} from "../../apis/recommendation/recommendation";
import { throttle } from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserNames } from "../../utils";
import GreetPage from "../greetpage/greetPage";
import Loading from "../loading";

const LandingPage = ({ isSidebarOpen, dataFromSidebar }) => {
  const [heading2, setHeading2] = useState(null);
  const [bookmarked, setBookmarked] = useState(null);
  const throttledSearch = useCallback(
    throttle(async (searchQuery) => {
      const token = await checkAndRefreshToken();
      if (!token) navigate("/login");
      const data = await searchByQuery(searchQuery, token);
      setHeading2(data);
      setAllChecked(false);
      setCheckedItems(Array(data?.recommendations?.length).fill(false));
    }, 1000),
    []
  );

  const throttledSearch2 = useCallback(
    throttle(async (searchQuery) => {
      const token = await checkAndRefreshToken();
      if (!token) navigate("/login");

      const data = await searchBookmarkByQuery(searchQuery, token);
      setBookmarked(data);
      setAllBookmarChecked(false);
      setBookmarCheckedItems(Array(data?.bookmarks?.length).fill(false));
    }, 1000),
    []
  );

  useEffect(() => {
    if (dataFromSidebar && dataFromSidebar?.recommendations) {
      setHeading2(dataFromSidebar?.recommendations);
      setAllChecked(false);
      setCheckedItems(
        Array(dataFromSidebar?.recommendations?.recommendations?.length).fill(
          false
        )
      );
    }
    if (dataFromSidebar && dataFromSidebar?.bookmarked_recommendations) {
      setBookmarked(dataFromSidebar?.bookmarked_recommendations);
      setAllBookmarChecked(false);
      setBookmarCheckedItems(
        Array(
          dataFromSidebar?.bookmarked_recommendations
            ?.bookmarked_recommendations?.length
        ).fill(false)
      );
    }
    // console.log(dataFromSidebar?.recommendations);
  }, [dataFromSidebar]);

  const handleSearch = (e) => {
    const searchQuery = e.target.value;
    if (activeTab === "Recommendations") {
      throttledSearch(searchQuery);
    } else {
      throttledSearch2(searchQuery);
    }
  };

  const updateBookmarks = async (id) => {
    const token = await checkAndRefreshToken();
    if (!token) navigate("/login");
    const config = {
      recommendation_id: id,
    };
    try {
      await bookmarkRecommendation(config, token);
    } catch (err) {
      toast.error("Failed to Update Boomark");
    }
  };

  const deleteBookmark = async (id) => {
    const token = await checkAndRefreshToken();
    if (!token) navigate("/login");
    const config = {
      recommendation_id: id,
    };
    try {
      await bookmarkDelete(config, token);
    } catch (err) {
      toast.error("Failed to unbookmarks recommendations");
    }
  };

  const computeDateTime = (updated) => {
    let hours = updated.getHours();

    const minutes = updated.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    const time = `${hours}:${formattedMinutes} ${ampm}`;
    return time;
  };

  const calculateTime = (date) => {
    const today = new Date();
    const updated = new Date(date);
    const updatedMonth = updated.toLocaleString("default", { month: "short" });
    const updatedDate = updated.getDate();
    const todayMonth = today.toLocaleString("default", { month: "short" });
    const todayDate = today.getDate();
    let recommendationDate;
    if (todayMonth === updatedMonth) {
      if (todayDate - updatedDate >= 1) {
        recommendationDate = `${updatedMonth} ${updatedDate}`;
      } else if (todayDate - updatedDate === 0) {
        recommendationDate = computeDateTime(updated);
      }
    } else {
      recommendationDate = computeDateTime(updated);
    }
    return recommendationDate;
  };

  const [userName, setUserName] = useState("");

  const getUserDetails = async () => {
    const user = await getUserNames();
    setUserName(user.data.userdetails.user_data.first_name);
  };

  const checkTokenExist = async () => {
    const token = await checkAndRefreshToken();
    if (!token) navigate("/login");
  };

  const [childRec, setChildRec] = useState([]);

  const fetchChildRecommendations = async () => {
    const childData = await fetchData();
    // console.log(childData);

    setChildRec(childData.custom_child_recommendations);
  };

  useEffect(() => {
    checkTokenExist();
    fetchChildRecommendations();
  }, []);

  useEffect(() => {
    getUserDetails();
    if (dataFromSidebar) {
      const newRec = dataFromSidebar?.recommendations;
      console.log(newRec);
      const combinedRec = dataFromSidebar?.combined_recommendations;
      if (combinedRec) {
        Object.keys(newRec).forEach((key) => {
          const exists = newRec[key].some((item) => item.id === combinedRec.id);

          if (!exists) {
            newRec[key].push(combinedRec);
          }
        });
      }
      setHeading2(newRec);
      console.log(heading2);
      setBookmarked(dataFromSidebar?.bookmarked_recommendations);
    }
  }, [dataFromSidebar]);
  // console.log(heading2);

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Recommendations");
  const [checkedItems, setCheckedItems] = useState([]);
  const [bookmarCheckedItems, setBookmarCheckedItems] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [allBookmarChecked, setAllBookmarChecked] = useState(false);

  const handleBookmarkToggle = async (item, id) => {
    console.log(id);
    const isBookmarked = bookmarked?.bookmarked_recommendations?.some(
      (rec) => rec.recommendation_id === id
    );

    if (isBookmarked) {
      await deleteBookmark(id);
      setBookmarked((prevBookmarked) => ({
        ...prevBookmarked,
        bookmarked_recommendations:
          prevBookmarked.bookmarked_recommendations?.filter(
            (rec) => rec.recommendation_id !== id
          ),
      }));
    } else {
      await updateBookmarks(id);

      const newBookmark = heading2.recommendations?.find(
        (rec) => rec.id === id
      );

      if (newBookmark) {
        setBookmarked((prevBookmarked) => ({
          ...prevBookmarked,
          bookmarked_recommendations: [
            ...prevBookmarked.bookmarked_recommendations,
            {
              recommendation_id: newBookmark.id,
              title: newBookmark.title,
              recommendation_content: newBookmark.content,
              last_updated: newBookmark.last_updated,
              file_type: item.file_type,
            },
          ],
        }));
      }
    }
  };

  const handleCheckToggle = (index) => {
    setCheckedItems(() => {
      const newCheckedItems = [...checkedItems];
      newCheckedItems[index] = !newCheckedItems[index];

      const allItemsChecked = newCheckedItems?.every((item) => item);
      setAllChecked(allItemsChecked);

      return newCheckedItems;
    });
  };

  const handleBookmarkCheckToggle = (index) => {
    setBookmarCheckedItems(() => {
      const newCheckedItems = [...bookmarCheckedItems];
      newCheckedItems[index] = !newCheckedItems[index];

      const allItemsChecked = newCheckedItems.every((item) => item);
      setAllBookmarChecked(allItemsChecked);

      return newCheckedItems;
    });
  };

  const handleAllCheckToggle = () => {
    if (activeTab === "Recommendations") {
      const newAllChecked = !allChecked;
      setAllChecked(newAllChecked);

      setCheckedItems(() => {
        const newCheckedItems = Array(heading2?.recommendations?.length).fill(
          newAllChecked
        );
        return newCheckedItems;
      });
    } else {
      const newAllChecked = !allBookmarChecked;
      setAllBookmarChecked(newAllChecked);
      setBookmarCheckedItems(() => {
        const newCheckedItems = Array(bookmarked?.bookmarks?.length).fill(
          newAllChecked
        );
        return newCheckedItems;
      });
    }
  };

  const LoadingRecommendationsMessage = () => {
    return (
      <div className="bg-white h-full rounded-lg flex justify-center">
        <Loading />
      </div>
    );
  };

  const handleIsNew = (item) => {
    if (item.is_new) {
      item.is_new = false;
    }
  };

  const handleBookmark = (id) => {
    const updatedItems = heading2?.recommendations.map((item) =>
      item.id === id ? { ...item, isBookmark: !item.isBookmark } : item
    );

    setHeading2((prevState) => ({
      ...prevState,
      recommendations: updatedItems,
    }));
  };

  const heading = (value) => {
    if (Array.isArray(value?.filename)) {
      return value;
    } else if (Array.isArray(value?.file_type)) {
      return childRec?.filter(
        (rec) => rec.file_type[0].Name === value.file_type[0].Name
      )[0];
    }

    return childRec?.filter(
      (rec) =>
        rec.file_type[0].Name === value?.file_type_name?.replace(/[{}\\]/g, "")
    )[0];
    // const resp = childRec?.filter(
    //   (rec) => rec.file_type[0].Name === value.file_type[0].Name
    // )[0];
    // console.log(resp);
    // return resp;
  };

  const [fileName, setFileName] = useState(null);

  if (dataFromSidebar === false) return <LoadingRecommendationsMessage />;

  if (!dataFromSidebar) return <GreetPage />;
  // setFileName(heading2?.recommendations[0].filename);
  return (
    <div className={`bg-white h-full rounded-lg flex justify-center`}>
      <div class="max-w-4xl mx-auto w-full flex flex-col mb-5">
        <div className="flex gap-4 pt-10 pb-5">
          <div
            className={`sio-circle-icon sio-circle-warning size-12
            ${isSidebarOpen ? "" : ""}`}
          >
            <img src={wave} alt="hand-wave-logo" className="size-8" />
          </div>
          <div>
            <h1 className="text-primary-dark text-xl">
              <span className="font-light">Hey</span> {userName}!{" "}
            </h1>
            <p className="text-secondary-dark text-sm font-light">
              Here are some important recommendations to gear up your week...
            </p>
          </div>
        </div>
        <div className="sio-card-shadow p-0 overflow-hidden flex flex-col grow">
          <div className="sio-tabs border-b">
            <button
              className={`sio-tab-link ${
                activeTab === "Recommendations"
                  ? "active relative text-primary-dark before:absolute before:h-[3px] before:rounded-full before:bg-primary before:w-14 before:bottom-0"
                  : "text-muted-dark"
              }`}
              onClick={() => {
                setActiveTab("Recommendations");
                setAllBookmarChecked(false);
                setBookmarCheckedItems(
                  Array(bookmarCheckedItems?.length).fill(false)
                );
              }}
            >
              Recommendations
            </button>
            <button
              className={`sio-tab-link ${
                activeTab === "Bookmarked"
                  ? "active text-primary-dark relative before:absolute before:h-[3px] before:rounded-full before:bg-primary before:w-14 before:bottom-0"
                  : "text-muted-dark"
              }`}
              onClick={() => {
                setActiveTab("Bookmarked");
                setAllChecked(false);
                setCheckedItems(Array(checkedItems?.length).fill(false));
              }}
            >
              Bookmarked
            </button>
          </div>
          <div className="sio-filter">
            <div className={`flex items-center gap-3`}>
              <Checkbox
                checked={
                  activeTab === "Recommendations"
                    ? allChecked
                    : allBookmarChecked
                }
                onChange={handleAllCheckToggle}
              />
              <div
                className={`relative inline-block text-left 3xl:text-[20px] z-50
                ${
                  isSidebarOpen
                    ? "sm:-translate-x-12 md:-translate-x-7 lg:translate-x-0"
                    : "sm:-translate-x-0"
                }`}
              >
                <Dropdown
                  data={[
                    "All Recommendations",
                    "Account",
                    "Settings",
                    "Profile",
                  ]}
                  css="flex justify-between w-[14rem] rounded-full border border-gray-300 shadow-sm items-center px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none
                  sm:w-[145%] lg:w-[14rem] 3xl:text-[16px]"
                />
              </div>
              <div
                className={`-translate-x-5 w-full
                  ${
                    isSidebarOpen
                      ? "sm:col-span-2 sm:translate-x-0 md:col-span-2 md:translate-x-0"
                      : "sm:col-span-2 sm:translate-x-0 md:col-span-2 md:translate-x-0"
                  }
              
              `}
              >
                <InputField
                  placeholder="Search for Recommendations"
                  css="flex-grow px-3 py-2 text-sm focus:outline-none focus:border-none"
                  icon={<FiSearch />}
                  onChange={handleSearch}
                />
              </div>
            </div>
            {activeTab === "Bookmarked" &&
              bookmarked?.bookmarked_recommendations && (
                <p className="pt-3 pl-3 text-sm font-semibold">
                  {Object.keys(bookmarked?.bookmarked_recommendations).length}{" "}
                  Recommendations found
                </p>
              )}
          </div>
          <div
            className={`${
              activeTab === "Recommendations" ? "" : ""
            } sio-flex-table sio-flex-table-scroll`}
          >
            {activeTab === "Recommendations" &&
              heading2 &&
              heading2.length === 0 && (
                <div className="h-[100%] flex justify-center items-center">
                  No Recommendations
                </div>
              )}
            {activeTab === "Bookmarked" &&
              bookmarked?.bookmarks?.length === 0 && (
                <div className="h-[100%] flex justify-center items-center">
                  No Bookmarks
                </div>
              )}
            {activeTab === "Recommendations"
              ? heading2?.recommendations?.map((value, ind) => (
                  <div
                    key={value.id}
                    className={`sio-flex-table-row cursor-pointer`}
                  >
                    {bookmarked?.bookmarked_recommendations?.some(
                      (rec) => rec.recommendation_id === value.id
                    )
                      ? (value["isBookmark"] = true)
                      : (value["isBookmark"] = false)}
                    {value.content && (
                      <>
                        <div class="flex-shrink-0 flex-grow-0 w-auto">
                          <Checkbox
                            checked={checkedItems[ind] || false}
                            onChange={() => handleCheckToggle(ind)}
                          />
                        </div>
                        <div
                          className="flex-1 truncate"
                          onClick={async () => {
                            await recommendationVisited(value.id);
                            handleIsNew(value);
                            // updateLocalStorage();
                            const aiRec = [
                              value?.qualitative_question,
                              value?.quantitative_question,
                            ];
                            navigate("/selectRecommendations", {
                              state: {
                                recommendationId: value.id,
                                allRecommendations: heading2,
                                bookmarkedData: bookmarked.bookmarks,
                                childRecommendations: childRec,
                                heading: heading(value),
                                fileName: value.filename,
                                aiChildRecommendation: aiRec,
                                fileTypeName:
                                  value?.file_type &&
                                  Array.isArray(value?.file_type)
                                    ? value?.file_type[0]?.Name
                                    : value?.file_type_name?.replace(
                                        /[{}\\]/g,
                                        ""
                                      ),
                              },
                            });
                          }}
                        >
                          <div className="flex gap-3 overflow-hidden items-center">
                            <i className="sio-circle-icon bg-dark/10 size-8 shrink-0">
                              <svg
                                className="size-5"
                                width="20"
                                height="24"
                                viewBox="0 0 20 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15.0586 6.1789C15.0576 6.17829 15.0567 6.17768 15.0558 6.17707C14.731 5.96425 14.2951 6.05501 14.0823 6.37981C13.8694 6.70462 13.9602 7.14047 14.285 7.35334C14.6839 7.61472 14.922 8.05489 14.922 8.53082C14.922 9.30625 14.2912 9.93711 13.5157 9.93711C12.7403 9.93711 12.1094 9.30625 12.1094 8.53082C12.1094 8.36366 12.1447 8.10336 12.1856 7.80199C12.4583 5.79161 12.7516 2.67049 8.98532 0.118499C8.4897 -0.197869 7.89057 0.160735 7.89057 0.702484C7.89057 0.703609 7.89053 0.704734 7.89053 0.705859V0.706281C7.88926 3.94773 6.26804 5.57321 4.55162 7.29404C2.71164 9.13876 0.859131 11.0351 0.859131 14.7086V14.8591C0.859131 19.8994 4.95973 24 10 24C15.0403 24 19.1409 19.8994 19.1409 14.8591C19.1409 10.4338 17.0326 7.47404 15.0586 6.1789ZM10 22.5937C5.73515 22.5937 2.26542 19.124 2.26542 14.8591V14.7086C2.26542 11.5775 3.85954 9.97921 5.54732 8.28716C7.45884 6.37072 8.83049 4.80167 9.20527 2.07118C11.2821 3.99793 10.9923 6.13695 10.7921 7.61299C10.7444 7.96484 10.7032 8.26874 10.7032 8.53087C10.7032 10.0817 11.9649 11.3434 13.5158 11.3434C14.7859 11.3434 15.8621 10.4972 16.2101 9.33892C17.0885 10.6806 17.7346 12.5301 17.7346 14.8592C17.7346 19.124 14.2649 22.5937 10 22.5937Z"
                                  fill="#666666"
                                />
                                <path
                                  d="M4.3749 4.26509C4.76322 4.26509 5.07804 3.95027 5.07804 3.56195V2.15566C5.07804 1.76734 4.76322 1.45251 4.3749 1.45251C3.98657 1.45251 3.67175 1.76734 3.67175 2.15566V3.56195C3.67175 3.95032 3.98657 4.26509 4.3749 4.26509Z"
                                  fill="#666666"
                                />
                                <path
                                  d="M14.2189 4.26509C14.6072 4.26509 14.922 3.95027 14.922 3.56195V2.15566C14.922 1.76734 14.6072 1.45251 14.2189 1.45251C13.8306 1.45251 13.5157 1.76734 13.5157 2.15566V3.56195C13.5157 3.95032 13.8306 4.26509 14.2189 4.26509Z"
                                  fill="#666666"
                                />
                                <path
                                  d="M14.2851 13H11.2223C10.8274 13 10.5074 13.3607 10.5074 13.8056C10.5072 14.2507 10.8274 14.6117 11.2221 14.6117H12.5593L9.50509 18.0544L7.84485 16.1829C7.56578 15.8683 7.11302 15.8683 6.83396 16.1829L5.20946 18.0139C4.93021 18.3287 4.93021 18.8389 5.20928 19.1536C5.48853 19.4684 5.9411 19.4682 6.22035 19.1536L7.3394 17.8924L8.99946 19.7639C9.1335 19.915 9.31538 20 9.50509 20C9.6946 20 9.87649 19.915 10.0105 19.7639L13.5702 15.7514V17.2584C13.5702 17.7033 13.8903 18.0643 14.2851 18.0643C14.68 18.0643 15 17.7033 15 17.2584V13.8059C15 13.3607 14.68 13 14.2851 13Z"
                                  fill="#383838"
                                />
                              </svg>
                            </i>
                            <h6 className="font-semibold text-secondary-900 text-sm">
                              {Array.isArray(value?.filename)
                                ? value?.category
                                : childRec.length > 0 &&
                                  heading(value)?.Category.split(" - ").length >
                                    1
                                ? heading(value)?.Category.split(" - ")[0]
                                : heading(value)?.Category}
                            </h6>
                            {/* {childRec.length > 0 &&
                              heading(value)?.Category.split(" - ").length >
                                1 && (
                                <b className="text-[8px] px-1.5 py-0.5 font-bold shadow-xl bg-gray-300 rounded-md self-center">
                                  {heading(value)?.Category.split(" - ")[1]}
                                </b>
                              )} */}
                            {value.is_new && (
                              <b className="sio-badge sio-badge-warning text-[10px] font-extrabold shadow">
                                NEW
                              </b>
                            )}
                            <p
                              className={`truncate text-sm text-secondary-dark whitespace-nowrap ${
                                isSidebarOpen ? "" : ""
                              }`}
                            >
                              {value.content.replace(/[^a-zA-Z ]/g, "")}
                            </p>
                          </div>
                        </div>
                        <div className="flex-shrink-0 flex-grow-0 w-auto min-w-[80px] flex items-center">
                          <button
                            onClick={() => {
                              handleBookmarkToggle(value, value.id);
                              handleBookmark(value.id);
                            }}
                          >
                            {value.isBookmark ? (
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.5 2C4.08333 2 3 3.16667 3 4.58333V16.9167C3 17.5833 3.41667 18.25 4 18.5C4.25 18.5833 4.41667 18.6667 4.66667 18.6667C5.08333 18.6667 5.41667 18.5 5.75 18.25L9.58333 14.6667L13.5 18.25C14 18.6667 14.6667 18.8333 15.25 18.5833C15.8333 18.3333 16.25 17.6667 16.25 17V4.58333C16.3333 3.16667 15.25 2 13.8333 2H5.5Z"
                                  fill="#F5B937"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  opacity="0.74"
                                  d="M5.5 2C4.08333 2 3 3.16667 3 4.58333V16.9167C3 17.5833 3.41667 18.25 4 18.5C4.25 18.5833 4.41667 18.6667 4.66667 18.6667C5.08333 18.6667 5.41667 18.5 5.75 18.25L9.58333 14.6667L13.5 18.25C14 18.6667 14.6667 18.8333 15.25 18.5833C15.8333 18.3333 16.25 17.6667 16.25 17V4.58333C16.3333 3.16667 15.25 2 13.8333 2H5.5ZM14.6667 4.58333V17L10.75 13.4167C10.4167 13.1667 10 13 9.66667 13C9.25 13 8.91667 13.1667 8.58333 13.4167L4.66667 16.9167V4.58333C4.66667 4.08333 5 3.66667 5.5 3.66667H13.8333C14.3333 3.66667 14.6667 4.08333 14.6667 4.58333Z"
                                  fill="#111111"
                                  fill-opacity="0.74"
                                />
                              </svg>
                            )}
                          </button>
                          <p className="text-[11px] text-muted34-dark grow text-right">
                            {value?.last_updated
                              ? calculateTime(value?.last_updated)
                              : calculateTime(value?.created_at)}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                ))
              : bookmarked?.bookmarked_recommendations?.map((value, ind) => (
                  <div
                    key={value.recommendation_id}
                    className={`sio-flex-table-row cursor-pointer`}
                  >
                    <div class="flex-shrink-0 flex-grow-0 w-auto">
                      <Checkbox
                        checked={bookmarCheckedItems[ind] || false}
                        onChange={() => handleBookmarkCheckToggle(ind)}
                      />
                    </div>
                    <div
                      className="flex-1 truncate"
                      onClick={async () => {
                        handleIsNew(value);
                        await bookmarkVisited(value.bookmark_id);
                        // updateLocalStorage();
                        const aiRec = [
                          value?.qualitative_question,
                          value?.quantitative_question,
                        ];
                        navigate("/selectRecommendations", {
                          state: {
                            recommendationId: value.recommendation_id,
                            allRecommendations: heading2,
                            bookmarkedData: bookmarked.bookmarks,
                            childRecommendations: childRec,
                            heading: heading(value),
                            fileName: value.filename,
                            aiChildRecommendation: aiRec,
                            fileTypeName:
                              value?.file_type &&
                              Array.isArray(value?.file_type)
                                ? value?.file_type[0]?.Name
                                : value?.file_type_name?.replace(/[{}\\]/g, ""),
                          },
                        });
                      }}
                    >
                      <div className="flex gap-3 overflow-hidden items-center">
                        <i className="sio-circle-icon bg-dark/10 size-8 shrink-0">
                          <svg
                            className="size-5"
                            width="20"
                            height="24"
                            viewBox="0 0 20 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.0586 6.1789C15.0576 6.17829 15.0567 6.17768 15.0558 6.17707C14.731 5.96425 14.2951 6.05501 14.0823 6.37981C13.8694 6.70462 13.9602 7.14047 14.285 7.35334C14.6839 7.61472 14.922 8.05489 14.922 8.53082C14.922 9.30625 14.2912 9.93711 13.5157 9.93711C12.7403 9.93711 12.1094 9.30625 12.1094 8.53082C12.1094 8.36366 12.1447 8.10336 12.1856 7.80199C12.4583 5.79161 12.7516 2.67049 8.98532 0.118499C8.4897 -0.197869 7.89057 0.160735 7.89057 0.702484C7.89057 0.703609 7.89053 0.704734 7.89053 0.705859V0.706281C7.88926 3.94773 6.26804 5.57321 4.55162 7.29404C2.71164 9.13876 0.859131 11.0351 0.859131 14.7086V14.8591C0.859131 19.8994 4.95973 24 10 24C15.0403 24 19.1409 19.8994 19.1409 14.8591C19.1409 10.4338 17.0326 7.47404 15.0586 6.1789ZM10 22.5937C5.73515 22.5937 2.26542 19.124 2.26542 14.8591V14.7086C2.26542 11.5775 3.85954 9.97921 5.54732 8.28716C7.45884 6.37072 8.83049 4.80167 9.20527 2.07118C11.2821 3.99793 10.9923 6.13695 10.7921 7.61299C10.7444 7.96484 10.7032 8.26874 10.7032 8.53087C10.7032 10.0817 11.9649 11.3434 13.5158 11.3434C14.7859 11.3434 15.8621 10.4972 16.2101 9.33892C17.0885 10.6806 17.7346 12.5301 17.7346 14.8592C17.7346 19.124 14.2649 22.5937 10 22.5937Z"
                              fill="#666666"
                            />
                            <path
                              d="M4.3749 4.26509C4.76322 4.26509 5.07804 3.95027 5.07804 3.56195V2.15566C5.07804 1.76734 4.76322 1.45251 4.3749 1.45251C3.98657 1.45251 3.67175 1.76734 3.67175 2.15566V3.56195C3.67175 3.95032 3.98657 4.26509 4.3749 4.26509Z"
                              fill="#666666"
                            />
                            <path
                              d="M14.2189 4.26509C14.6072 4.26509 14.922 3.95027 14.922 3.56195V2.15566C14.922 1.76734 14.6072 1.45251 14.2189 1.45251C13.8306 1.45251 13.5157 1.76734 13.5157 2.15566V3.56195C13.5157 3.95032 13.8306 4.26509 14.2189 4.26509Z"
                              fill="#666666"
                            />
                            <path
                              d="M14.2851 13H11.2223C10.8274 13 10.5074 13.3607 10.5074 13.8056C10.5072 14.2507 10.8274 14.6117 11.2221 14.6117H12.5593L9.50509 18.0544L7.84485 16.1829C7.56578 15.8683 7.11302 15.8683 6.83396 16.1829L5.20946 18.0139C4.93021 18.3287 4.93021 18.8389 5.20928 19.1536C5.48853 19.4684 5.9411 19.4682 6.22035 19.1536L7.3394 17.8924L8.99946 19.7639C9.1335 19.915 9.31538 20 9.50509 20C9.6946 20 9.87649 19.915 10.0105 19.7639L13.5702 15.7514V17.2584C13.5702 17.7033 13.8903 18.0643 14.2851 18.0643C14.68 18.0643 15 17.7033 15 17.2584V13.8059C15 13.3607 14.68 13 14.2851 13Z"
                              fill="#383838"
                            />
                          </svg>
                        </i>
                        <h6 className="font-semibold text-secondary-900 text-sm">
                          {childRec.length > 0 &&
                          heading(value)?.Category.split(" - ").length > 1
                            ? heading(value)?.Category.split(" - ")[0]
                            : heading(value)?.Category}
                        </h6>
                        {value.is_new && (
                          <b className="sio-badge sio-badge-warning text-[10px] font-extrabold shadow">
                            NEW
                          </b>
                        )}
                        {childRec.length > 0 &&
                          heading(value)?.Category.split(" - ").length > 1 && (
                            <b className="sio-badge sio-badge-light">
                              {heading(value)?.Category.split(" - ")[1]}
                            </b>
                          )}
                        <p
                          className={`truncate text-sm text-secondary-dark whitespace-nowrap ${
                            isSidebarOpen ? "" : ""
                          }`}
                        >
                          {value.recommendation_content.replace(
                            /[^a-zA-Z ]/g,
                            ""
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="flex-shrink-0 flex-grow-0 w-auto min-w-[80px] flex items-center">
                      <button
                        onClick={() => {
                          handleBookmarkToggle(value, value.recommendation_id);
                          handleBookmark(value);
                        }}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.5 2C4.08333 2 3 3.16667 3 4.58333V16.9167C3 17.5833 3.41667 18.25 4 18.5C4.25 18.5833 4.41667 18.6667 4.66667 18.6667C5.08333 18.6667 5.41667 18.5 5.75 18.25L9.58333 14.6667L13.5 18.25C14 18.6667 14.6667 18.8333 15.25 18.5833C15.8333 18.3333 16.25 17.6667 16.25 17V4.58333C16.3333 3.16667 15.25 2 13.8333 2H5.5Z"
                            fill="#F5B937"
                          />
                        </svg>
                      </button>
                      <p className="text-[11px] text-muted34-dark grow text-right">
                        {value?.last_updated
                          ? calculateTime(value?.last_updated)
                          : calculateTime(value?.created_at)}
                      </p>
                    </div>
                  </div>
                ))}
          </div>
        </div>
        <div className="sio-card-shadow mt-2">
          <ChatInputField
            allRecommendations={heading2}
            bookmarkedData={bookmarked}
            childRec={childRec}
            fileName={fileName}
          />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
