/* eslint-disable no-unused-vars */
import { Box, CircularProgress } from "@mui/material";
import React, { useRef, useState } from "react";
import { IoMdSend } from "react-icons/io";
import { TiAttachment } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import { computeSignature, getUserNames } from "../utils";
import { devApis } from "../constants/constant";
import { addMessageToSession } from "../apis/recommendation/recommendation";
import { checkAndRefreshToken } from "../utils/authUtils";

const ChatInputField = ({
  allRecommendations,
  bookmarkedData,
  childRec,
  fileName,
}) => {
  const [file, setFile] = useState(null);
  const [prompt, setPrompt] = useState(null);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  let fileRes;
  console.log(allRecommendations, bookmarkedData);
  const clearFile = (event) => {
    event.preventDefault();

    setFile(null);
    fileInputRef.current.value = null;
  };

  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handlePrompt = (e) => {
    setPrompt(e.target.value);
  };

  const heading = (value) => {
    // console.log(value);
    return childRec?.filter(
      (rec) => rec.file_type[0].Name === value.file_type_name[0]
    )[0];
  };

  const fileUploadRequest = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const getUUID = await getUserNames();
      const orgUuid = getUUID.data.org_uuid;
      formData.append("org_uuid", orgUuid);
      console.log(formData);
      const secretKey = "wBLm_uRNsyuxHWZrIufExXRBI-uE0aDkjT7xsKLbn70=";

      const fileContent = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const binaryString = reader.result; // File as binary string
          resolve(binaryString);
        };
        reader.onerror = reject;
        reader.readAsBinaryString(file); // Read as binary string
      });

      const fileUploadsignature = computeSignature(
        secretKey,
        "POST",
        "/upload_file",
        {},
        { org_uuid: orgUuid },
        "",
        fileContent
      );
      try {
        const fileUploadResponse = await fetch(
          `${devApis.PYTHON_BASE_URL}/upload_file`,
          {
            method: "POST",
            headers: {
              "x-signature": fileUploadsignature,
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: formData,
          }
        );

        const fileUploadData = await fileUploadResponse.json();
        fileRes = fileUploadData;

        const response = await fetch(
          `${devApis.PYTHON_BASE_URL}/convert_csv_to_table`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: formData,
          }
        );

        await response.json();
        const getUUID = await getUserNames();
        const uuid = getUUID.data.org_uuid;
        const params = new URLSearchParams();
        params.append("org_uuid", uuid);

        const getRecommendationsSignature = computeSignature(
          secretKey,
          "GET",
          "/get_recommendations",
          { s3_key: fileRes.s3_key, org_uuid: uuid },
          {},
          "",
          ""
        );

        const respS3 = await fetch(
          `${devApis.PYTHON_BASE_URL}/get_recommendations?s3_key=${
            fileRes.s3_key
          }&${params.toString()}`,
          {
            method: "GET",
            headers: {
              "x-signature": getRecommendationsSignature,
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = await respS3.json();
        console.log(data);
        const aiRec = [
          data?.follow_up_questions?.qualitative_question,
          data?.follow_up_questions?.quantitative_question,
        ];
        navigate("/selectRecommendations", {
          state: {
            recommendationId: 0,
            allRecommendations: allRecommendations,
            bookmarkedData: bookmarkedData,
            promptData: data.recommendations,
            isFileUpdate: true,
            fileTypeName: data.file_type_name[0],
            heading: heading(data),
            childRecommendations: childRec,
            aiChildRecommendation: aiRec,
          },
        });
      } catch (error) {
        toast.error("error:", error.message);
        setIsLoading(false);
      }
    } else {
      try {
        const promptResponse = await fetch(
          `${devApis.PYTHON_BASE_URL}/start_conversation`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              recommendation_id: 23,
            }),
          }
        );

        const data = await promptResponse.json();
        const newSessionId = data.session_id;

        if (newSessionId) {
          // const response = await fetch(
          //   `${devApis.PYTHON_BASE_URL}/add_message_to_session`,
          //   {
          //     method: "POST",
          //     headers: {
          //       "Content-Type": "application/json",
          //       Authorization: `Bearer ${localStorage.getItem("token")}`,
          //     },
          //     body: JSON.stringify({
          //       session_id: newSessionId,
          //       prompt: prompt,
          //     }),
          //   }
          // );
          const token = await checkAndRefreshToken();
          const response = await addMessageToSession(
            newSessionId,
            prompt,
            token,
            fileName
          );

          const data2 = await response.json();
          setFile(null);
          console.log(data.file_type_name[0]);
          navigate("/selectRecommendations", {
            state: {
              recommendationId: 0,
              allRecommendations: allRecommendations,
              bookmarkedData: bookmarkedData,
              sessionId: newSessionId,
              promptData: data2,
              isFileUpdate: false,
              promptInput: prompt,
              childRecommendations: childRec,
              fileTypeName: data.file_type_name[0],
            },
          });
        }
      } catch (error) {
        setFile(null);
        console.error("Error during API calls:", error);
        toast.error("error:", error.message);
        setIsLoading(false);
        return null;
      }
    }
    setFile(null);
  };

  const getFileRecommendation = async () => {
    const response = await fetch(
      `${devApis.PYTHON_BASE_URL}/get_recommendations?s3_key=${fileRes.s3_key}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    const data = await response.json();

    navigate("/selectRecommendations", {
      state: {
        recommendationId: 0,
        allRecommendations: allRecommendations,
        bookmarkedData: bookmarkedData,
        promptData: data.recommendations[fileRes.s3_key],
        childRecommendations: childRec,
      },
    });
  };

  const handleSubmit = async () => {
    if (!file && !prompt) {
      toast.error("Please provide input");
      return;
    }
    setIsLoading(true);
    await fileUploadRequest();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className="flex flex-col p-4">
      {isLoading ? (
        <div className="flex justify-center items-center">
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        </div>
      ) : (
        <>
          <div className="absolute top-3 left-0 w-full -mt-2"></div>
          <input
            className="border-none w-full mb-3 text-md focus:outline-none"
            placeholder="Ask me anything..."
            onChange={handlePrompt}
            onKeyDown={handleKeyDown}
            autoComplete="off"
          />
          <div className="flex items-center justify-between">
            <label htmlFor="fileInput" className="sio-btn-file">
              {file && (
                <span className="absolute group pl-1.5 h-5 w-5 bg-darkGreen text-sm rounded-full translate-x-[8.7rem] -translate-y-4">
                  1
                  <AiFillCloseCircle
                    onClick={clearFile}
                    className="absolute top-0 right-0 h-5 w-5 text-white bg-red-500 rounded-full cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                  />
                </span>
              )}
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_109_12330)">
                  <path d="M7.81714 5.24795C7.81714 5.24795 8.42603 4.69513 7.85011 4.11921C7.27419 3.54329 6.68813 4.11892 6.68813 4.11892L5.68396 5.12318C4.67231 6.13522 4.54743 7.65616 5.55898 8.66734C6.57104 9.67938 8.12742 9.58986 9.13867 8.5779L10.8329 6.88449C12.3891 5.3283 12.3891 2.79592 10.8329 1.23975L10.7648 1.17164C9.20778 -0.385337 6.67539 -0.385337 5.11918 1.17164L1.16743 5.12289C-0.389145 6.67905 -0.389145 9.21144 1.16743 10.7684L1.23555 10.8365C2.44731 12.048 4.26942 12.3369 5.80734 11.584C6.22019 11.382 6.66887 10.9992 6.37271 10.4624C6.05937 9.8945 5.50893 10.0182 5.2179 10.1509C4.34939 10.5087 3.06902 10.4117 2.36458 9.70763L2.29646 9.63871C1.3624 8.70545 1.3624 7.18587 2.29646 6.25183L6.24821 2.30056C7.18227 1.3665 8.70183 1.3665 9.63511 2.30056L9.704 2.36867C10.6373 3.30273 10.6373 4.82151 9.704 5.75557L8.00971 7.44897C7.62066 7.83802 7.07703 7.92757 6.68799 7.53841C6.29894 7.14926 6.42391 6.64127 6.81296 6.25212L7.81714 5.24795Z" />
                </g>
                <defs>
                  <clipPath id="clip0_109_12330">
                    <rect width="12" height="12" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span className="ms-2 leading-none">Attach References</span>
              <input
                id="fileInput"
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                ref={fileInputRef}
                className="hidden"
                autoComplete="off"
              />
            </label>
            <button
              className="soi-btn soi-btn-fab btn-primary"
              type="button"
              onClick={handleSubmit}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.870045 2.36133C0.753378 1.89533 0.919378 1.40467 1.29404 1.104C1.66871 0.803334 2.18404 0.748668 2.61338 0.963334L14.4867 6.9C14.9034 7.108 15.1667 7.534 15.1667 8C15.1667 8.466 14.9034 8.892 14.4867 9.1L2.61338 15.0367C2.18404 15.2513 1.66871 15.1967 1.29404 14.896C0.919378 14.5953 0.753378 14.1047 0.870045 13.6387L2.11338 8.66667L9.33338 8L2.11338 7.33333L0.870045 2.36133Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ChatInputField;
