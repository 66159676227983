// Button.js
import React, { useMemo } from 'react';

const Button = ({ type = 'button', onClick, variant = 'default', size = 'large', className = '', children }) => {

  // Variant CSS logic
  const getVariantClass = (variant) => {
    switch (variant) {
      case "primary":
        return "btn-primary";
      case "secondary":
        return "btn-secondary";
      case "danger":
        return "bg-btnDanger-gradient text-white hover:bg-red-700";
      case "dark":
        return "btn-dark";
      case "light":
          return "btn-light";
      default:
        return "btn-default";
    }
  };

  // Size CSS logic using useMemo
  const sizeCss = useMemo(() => {
    switch (size) {
      case "xs":
        return "btn-xs"; // Small size style
      case "sm":
        return "btn-sm"; // Small size style
      case "medium":
        return "w-1/2"; // Medium width style
      case "large":
        return ""; // Large width style
      case "full":
        return "w-full"; // Full width style
      default:
        return ""; // Default to large
    }
  }, [size]);

  return (
    <button
      type={type}
      onClick={onClick}
      className={`sio-btn ${getVariantClass(variant)} ${sizeCss} ${className}`} >
      {children}
    </button>
  );
};

export default Button;
