/* eslint-disable no-unused-vars */
import { useRef, useMemo } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Login from "./Pages/login/login";
import "./App.css";
import { StrivedContext } from "./context/context";
import { useState } from "react";
import LoadingOverlay from "./components/loadingOverlay/loadingOverlay";
import { Toaster } from "react-hot-toast";
import ModalBackdrop from "./components/ModalBackdrop";
import Signup from "./Pages/signup/signup";
import Layout from "./components/layout/layout";
import LandingPage from "./Pages/landingpage/landingPage";
import SelectRecommendatonsPage from "./Pages/selectrecommendationspage/SelectRecommendationsPage";
import ChildRecommendation from "./components/data_ingestion/child-recommendation/index";
import CustomRecommendation from "./components/data_ingestion/custom-recommendation/index";
import FileType from "./components/data_ingestion/file-type/index";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ToastContainer } from "react-toastify";

import { ThemeProvider } from "@mui/material/styles";
import { lightTheme, darkTheme } from "./theme";
import FileUpload from "./components/data_ingestion/fileUpload/fileUpload";

function App() {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const theme = useMemo(
    () => (isDarkMode ? darkTheme : lightTheme),
    [isDarkMode]
  );

  const routesWithoutLayout = [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/signup",
      element: <Signup />,
    },
  ];

  const routesWithLayout = [
    {
      path: "/",
      element: <LandingPage />,
    },
    {
      path: "/selectRecommendations",
      element: <SelectRecommendatonsPage />,
    },
    {
      path: "/customRecommendation",
      element: <CustomRecommendation />,
    },
    {
      path: "/childRecommendation",
      element: <ChildRecommendation />,
    },
    {
      path: "/fileType",
      element: <FileType />,
    },
    {
      path: "/fileUpload",
      element: <FileUpload />,
    },
  ];
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [promptHistory, setPromptHistory] = useState([]);
  const [recs, setRecs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingOverlay, setLoadingOverlay] = useState(false);

  const modalRef = useRef();

  return (
    <ThemeProvider theme={lightTheme}>
      <GoogleOAuthProvider clientId="115840353103-nrk6b4rs4m72od7shkgq9lreto10k88r.apps.googleusercontent.com">
        <StrivedContext.Provider
          value={{
            selectedFiles: selectedFiles,
            setSelectedFiles: setSelectedFiles,
            loading: loading,
            setLoading: setLoading,
            loadingOverlay: loadingOverlay,
            setLoadingOverlay: setLoadingOverlay,
            promptHistory: promptHistory,
            setPromptHistory: setPromptHistory,
            recs: recs,
            setRecs: setRecs,
          }}
        >
          <div>
            <Router>
              <Routes>
                {routesWithoutLayout.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                ))}

                {routesWithLayout.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={<Layout>{route.element}</Layout>}
                  />
                ))}
              </Routes>
            </Router>
          </div>
          {loadingOverlay && <LoadingOverlay />}
          <ModalBackdrop ref={modalRef} />
          <Toaster position="top-right" />
          <ToastContainer />
        </StrivedContext.Provider>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

export default App;
