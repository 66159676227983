import React, { createContext, useState } from "react";
import Sidebar from "../sidebar/sidebar";

export const DataContext = createContext();

const Layout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [dataFromSelectRecommendation, setDataFromSelectRecommendation] =
    useState(null);
  const [dataFromSidebar, setDataFromSidebar] = useState(null);

  const handleDataFromSelectRecommendation = (data) => {
    setDataFromSelectRecommendation(data);
  };

  const handleDataFromSidebar = (data) => {
    setDataFromSidebar(data);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      isSidebarOpen,
      dataFromSidebar,
    })
  );

  return (
    <DataContext.Provider
      value={{
        handleDataFromSelectRecommendation,
        handleDataFromSidebar,
        dataFromSelectRecommendation,
        dataFromSidebar,
      }}
    >
      <div className="bg-dark relative flex h-screen py-3 pe-3">
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          recId={dataFromSelectRecommendation}
          sendDataToParent={handleDataFromSidebar}
          toggleSidebar={toggleSidebar}
        />
        <div
          className={`flex-1 ${
            isSidebarOpen
              ? "ml-[19rem]"
              : "ml-20"
          } transition-margin duration-300 h-[100%] w-[1%]`}
        >
          {childrenWithProps}
        </div>
      </div>
    </DataContext.Provider>
  );
};

export default Layout;
