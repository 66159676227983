import React, { Component } from "react";
import wave from "../../assets/ic_wave.svg";

class GreetPage extends Component {
  render() {
    // const userDetails = JSON.parse(localStorage.getItem("user"));
    return (
      <>
        {/* <div className="relative min-h-full flex flex-grow justify-start items-stretch bg-gradient-to-b from-profile to-white md:rounded-tr-[60px] md:rounded-bl-[60px]">
            <div className="flex flex-col flex-1 bg-white text-center justify-center items-center md:rounded-tr-[60px] md:rounded-bl-[60px]">
                <div className="bg-lightGold rounded-full flex items-center justify-center p-8">
                    <img src={wave} alt="wave" className="mr-2 w-[65px]" />
                </div>
                <h1 className="font-ibm font-bold text-[32px] py-3 capitalize">
                    Hi!
                    {/* {userDetails && userDetails["email"]?.split("@")[0]} */}
        {/* </h1>
                <p className="font-ibm font-normal italic">
                    Click on one or many of your data files to begin seeing recommendations.
                </p>
            </div>
        </div> */}
        <div className="bg-white h-full rounded-lg flex justify-center">
          <div className="sio-blankslate mt-24">
            <div className="sio-circle-icon sio-circle-warning size-20 mb-9">
              <img
                src={wave}
                alt="wave"
                className="w-[55px] mx-auto justify-center"
              />
            </div>
            <h1 className="text-primary-dark text-4xl mb-5">
              <span className="font-light">Hey</span>{" "}
              {localStorage.getItem("userName")}!
            </h1>
            <p className="text-secondary-dark font-light text-lg">
              {" "}
              Click on one or more document(s) on the left side. to begin seeing
              recommendations.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default GreetPage;
